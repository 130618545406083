<template>
<offers-details-container :item="subscription" :item_id="subscription_id" :baseUrl="baseUrl" :closePanel="closePanel">
    <template v-if="subscription.services && subscription.services.length" slot="list_subscriptions">
        <q-separator />
        <b-list-group class="list-group-accent">
            <b-list-group-item class="list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase small">
                {{ translate('services') }}
            </b-list-group-item>
            <q-separator />
            <b-list-group-item class="list-group-item-accent-info list-group-item-divider">
                <p>
                    {{ translate('this_subscription_includes_following_services') }}
                </p>
                <offers-list v-if="subscription.services" :list="subscription.services" :typeEnable="true" :baseUrl="baseUrl" :showDetailPanel="false" :showShopButton="false" />
            </b-list-group-item>
        </b-list-group>
    </template>
</offers-details-container>
</template>

<script>
import OffersDetailsContainer from '../../../template-parts/OffersDetailsContainerCustomer'
import ServiceDetails from '../../services/show/ServiceDetails'
import ShopService from '../../services/forms/BuyService'
import OffersList from '../../../template-parts/OffersListCustomer'

export default {
    name: 'SubscriptionDetails',
    props: ['subscription_id', 'baseUrl', 'type'],
    components: {
        'offers-details-container': OffersDetailsContainer,
        'offers-list': OffersList
    },
    data: function () {
        return {
            subscription: {
                name: null,
                description: '',
                services_name: {},
                credits: {},
                product_name: {},
                quantities: {},
                services: [],
            },
            page: 1,
            msg: null,
        }
    },
    created: function () {
        this.getSubscription()
    },
    methods: {
        getSubscription: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            var content_url = 'subscriptions/'
            if (this.type && this.type == 'custom') {
                content_url = 'custom-subscriptions/'
            }
            axios.get(baseUrl + content_url + this.subscription_id, {
                    headers: headers
                })
                .then(response => {
                    this.subscription = response.data.item
                    if (response.data.item.services) {
                        this.subscription.services = response.data.item.services.map(entity => {
                            let array = entity
                            entity['name'] = entity.service_name
                            entity['price'] = entity.service_price
                            entity['id'] = entity.service_id
                            return array
                        })
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        closePanel: function () {
            this.$emit('closePanel')
        },
        showServiceDetailPanel: function (id) {
            const panelInstance = this.$showPanel({
                component: ServiceDetails,
                props: {
                    baseUrl: this.baseUrl,
                    service_id: id,
                }
            })
        },
        showShopPanel: function (id) {
            const panelInstance = this.$showPanel({
                component: ShopService,
                props: {
                    service_id: id
                }
            })
        },
    }
}
</script>
