<template>
<div class="fit">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless>
        <b-tab class="p-0">
            <template slot="title">
                <i class="fas fa-box-open"></i>
            </template>
            <b-list-group class="list-group-accent">
                <b-list-group-item @click.prevent="isHidden = !isHidden" class="list-group-item-accent-secondary bg-light font-weight-bold text-uppercase cursor-pointer">
                    <q-icon :title="translate('Click to show or hide the included products')" name="list" />&nbsp;
                    <strong class="position-relative pr-4" style="display: inline-block; width: auto;">
                        {{translate('included_products')}}
                        <q-badge color="primary" floating>{{ included_products && included_products.length ? included_products.length : '0' }}</q-badge>
                    </strong>
                </b-list-group-item>
                <b-list-group-item v-if="!isHidden && included_products && included_products.length" class="list-group-item-accent-info">
                    <div class="list-container-scroll vh-31">
                        <q-item class="list-item" clickable ripple v-for="(product, i) in included_products" :key="'included-product-' + i + '-' + product.id">
                            <q-item-section side>
                                <div class="image-thumbnail m-0">
                                    <img v-if="product.main_image_link" :src="baseUrl + product.main_image_link" />
                                    <i v-else class="fa fa-photo fa-2x"></i>
                                </div>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>{{ product.product_name }}</q-item-label>
                                <q-item-label caption>{{ translate('quantity') }}: {{ product.quantity }}</q-item-label>
                            </q-item-section>
                            <q-item-section side>
                                <q-btn dense icon="delete" @click="showDeletePanel(product.id)" flat color="danger" />
                            </q-item-section>
                        </q-item>
                    </div>
                    <div v-if="!included_products">
                        <q-item class="m-0 pt-3">{{ translate('No included products') }}</q-item>
                    </div>
                </b-list-group-item>
                <q-separator />
                <b-list-group-item class="list-group-item-accent-secondary bg-light font-weight-bold text-uppercase">
                    <q-icon name="add" />&nbsp;
                    {{translate('add_product')}}
                </b-list-group-item>
                <b-list-group-item class="list-group-item-accent-info">
                    <include-item-form :search_placeholder="translate('find_product')" @update_list="updateIncludedItems" :baseUrl="baseUrl" :item_id="subscription_id" :post_url="post_url" :get_url="get_url" :entity_type="entity_type" :item_type="entity_type" />
                </b-list-group-item>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import {
    eventBus
} from "../../../../main"

import DeleteSubscriptionIncludedItem from '../forms/DeleteSubscriptionIncludedItem'
import IncludeItemForm from '../../../template-parts/IncludeItemForm'

export default {
    name: 'AddIncludedProducts',
    props: ['subscription_id', 'subscription_type', 'baseUrl'],
    components: {
        'include-item-form': IncludeItemForm
    },
    computed: {
        entity_type: function () {
            return this.subscription_type + '_subscription_product'
        }
    },
    data() {
        return {
            post_url: 'subscriptions-products-quantity',
            get_url: this.getGetUrl(),
            isHidden: false,
            included_products: {},
            msg: '',
            page: 1,
        }
    },
    created() {
        this.getIncludedProducts()
    },
    methods: {
        getGetUrl: function() {
            return 'subscriptions/' + this.subscription_id + '/non_included_products'
        },
        updateIncludedItems: function () {
            this.included_products = []
            this.getIncludedProducts()
        },
        getProduct: function () {
            var productUrl = baseUrl + 'products?page=' + this.page + '&items_per_page=' + this.$items_per_page

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }

            axios.get(productUrl, {
                    headers: headers
                })
                .then(response => {
                    this.products = response.data.items.map(parent2 => {
                        let array = parent2
                        return array
                    })
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        getIncludedProducts: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            var url = 'subscriptions-products-quantity'

            axios.get(baseUrl + url + '/' + this.subscription_id, {
                    headers: headers
                })
                .then(response => {
                    this.included_products = response.data.items
                })
                .catch(function (error) {
                    // console.log(error)
                })

        },
        getProductName: function () {
            var productUrl = baseUrl + 'products?page=' + this.page + '&items_per_page=' + this.$items_per_page

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }

            axios.get(productUrl, {
                    headers: headers
                })
                .then(response => {
                    this.product_name = response.data.items.map(parent2 => {
                        let array = parent2
                        return array
                    })

                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showDeletePanel: function (item_id) {
            const panelInstance = this.$showPanel({
                component: DeleteSubscriptionIncludedItem,
                props: {
                    item_id: item_id,
                    entity_type: this.entity_type,
                    post_url: this.post_url,
                    panel_title: this.translate('delete_subscription_included_product'),
                    panel_message: this.translate('deleting_subscription_included_product')
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.updateIncludedItems()
                        this.$emit('update_list')
                        eventBus.$emit('update_all')
                    }
                })
        },
        closePanel: function () {
            this.$emit('closePanel', {})
        }
    }
}
</script>

<style lang="scss" scoped>
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }
}
</style>
