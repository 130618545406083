<template>
<div class="animated fadeIn fit">
    <q-tabs clickable hoverable inline-label no-caps v-model="tab" class="subscriptions-tabs no-controls" align="left">
        <q-tab class="custom-tab" name="predefined">
            {{ translate('predefined') }}
        </q-tab>
        <q-tab class="custom-tab" name="custom">
            {{ translate('custom') }}
        </q-tab>
    </q-tabs>
    <div class="m-0 mb-4 p-0 overflow-hidden">
        <q-tab-panels class="bg-transparent m-0 p-0" v-model="tab">
            <q-tab-panel class="p-0" name="predefined">
                <subscription-list :subscription_type="tab" />
            </q-tab-panel>
            <q-tab-panel class="p-0" name="custom">
                <subscription-list :subscription_type="tab" />
            </q-tab-panel>
        </q-tab-panels>
    </div>
</div>
</template>

<script>
import SubscriptionList from './show/SubscriptionList'

export default {
    name: 'SubscriptionItems',
    components: {
        'subscription-list': SubscriptionList,
    },

    data: function () {
        return {
            tab: 'predefined',
        }
    },
}
</script>
