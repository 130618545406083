<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="fas fa-shopping-cart" class="mr-1" /><strong>{{ panel_title ? panel_title : translate('buy') }}: {{ item_name }}</strong>
            </template>
            <b-list-group class="list-group-accent">
                <b-list-group-item class="font-weight-bold pt-2 pb-0">
                    {{ translate('customer_add_sale_select_buyer_type') }}:
                </b-list-group-item>
                <b-form @submit.prevent="computeSale">
                    <!-- [+] Customer -->
                    <b-list-group-item tag="label" class="pt-1 pb-1 cursor-pointer">
                        <div class="row m-0 align-items-center">
                            <div class="col pl-0">
                                <input type="radio" id="customer" name="customer" class="hidden" v-model="selected_type" value="customer" @click="showCustomersList" :checkedCustomer="checkedCustomer ? 'checkedCustomer' : ''">
                                <label class="cursor-pointer" for="customer">
                                    <q-icon size="1.5rem" class="mr-1" :color="selected_type === 'customer' ? 'primary' : 'secondary'" name="fas fa-user" />
                                    {{ translate('customer_add_sale_me') }} 
                                    <q-badge class="p-2 ml-1" color="blue-6" v-if="checkedCustomer && selected_customer_name">{{ selected_customer_name }}</q-badge>
                                </label>
                            </div>
                        </div>
                    </b-list-group-item>
                    <!-- [-] Customer -->
                    <!-- [+] Group -->
                    <b-list-group-item tag="label" class="pt-1 pb-1 cursor-pointer mb-4">
                        <div class="row m-0 align-items-center">
                            <div class="col pl-0">
                                <input type="radio" id="group" name="group" class="hidden" v-model="selected_type" value="group" @click="showGroupList" :checkedGroup="checkedGroup ? 'checkedGroup' : ''">
                                <label class="cursor-pointer" for="group">
                                    <q-icon size="1.5rem" class="mr-1" :color="selected_type === 'group' ? 'primary' : 'secondary'" name="fas fa-group" />
                                    {{ translate('customer_add_sale_my_group') }}
                                    <q-badge class="p-2 ml-1" color="blue-6" v-if="checkedGroup && selected_group_name">{{ selected_group_name }}</q-badge>
                                </label>
                            </div>
                        </div>
                    </b-list-group-item>
                    <!-- [-] Group -->
                    <!-- [+] Subscription -->
                    <q-card class="p-3" flat>
                        <div class="row m-0 align-items-center">
                            <div class="col pl-0">
                                <div class="mb-3" flat color="primary" emit-value :id="subscription_value(item.id, item.type)" :val="subscription_value(item.id, item.type)">
                                    <q-icon size="1.5rem" class="mr-1" color="primary" name="fas fa-atom" />
                                    <q-badge class="p-2 ml-1" color="black" v-if="item.name">{{ item.name }}</q-badge>
                                    <q-badge class="p-2 ml-1" color="black" v-if="item.name && sale.valid_from">{{ translate('valid_from') + ': ' + prettyDate(sale.valid_from) }}</q-badge>
                                    <q-badge class="p-2 ml-1 text-dark" color="amber" v-if="item.name && response_sales_compute.valid_until">{{ translate('valid_until') + ': ' + prettyDate(response_sales_compute.valid_until) }}</q-badge>
                                </div>
                                <q-card class="p-0 mb-2">
                                    <q-input borderless @focus="$refs.qDateProxy.show()" @click="$refs.qDateProxy.show()" @input="$refs.qDateProxy.show()" light square color="primary" :label="translate('valid_from')" dense class="full-width m-0 cursor-pointer" id="valid_from" v-model="sale.valid_from" no-error-icon>
                                        <template v-slot:prepend>
                                            <q-icon name="event" class="cursor-pointer pointer-events-none">
                                                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                                    <q-date first-day-of-week="1" v-model="sale.valid_from" mask="YYYY-MM-DD" @input="(val) => onUpdateDateValidFrom(sale.valid_from)" />
                                                </q-popup-proxy>
                                            </q-icon>
                                        </template>
                                    </q-input>
                                </q-card>
                                <q-card class="included-services-block" v-if="item.type=== 'custom'">
                                    <strong class="small">{{ translate('subscription_included_services') }}</strong>
                                    <div class="text-right"><small>{{ translate('credits') }}: <strong>{{ selected_remaining_custom_subscription_credits }} / {{ selected_custom_subscription_credits }}</strong></small></div>
                                    <div class="subscription_services_list_wrapper">
                                        <q-item tag="label" class="list-item pr-0" clickable v-ripple v-for="(service,i) in custom_subscription_services" :key="'custom-subscription-service-' + i + '-' + service.service_id">
                                            <q-item-section>
                                                <q-item-label>
                                                    {{ service.service_name }}
                                                    <strong v-if="service.service_type" class="text-muted">({{ translate(service.service_type.replace("s_", '')) }})</strong>
                                                </q-item-label>
                                            </q-item-section>
                                            <q-item-section class="pt-2" side>
                                                <q-input :max="max_number !== null && service.service_type === 's_streaming' ? max_number : null" no-error-icon 
                                                :rules="max_number !== null && service.service_type === 's_streaming' ? [value => value <= max_number || $toasted.error(max_number_error, {duration: toasted_duration})] : [value => value >= 0 || $toasted.error('positive_numeric_value_only', {duration: toasted_duration})]" emit-value v-model.number="service.new_number" @input="setSelectedSubscriptionServices(service)" @wheel="$event.target.blur()" type="number" outlined dense style="max-width: 150px">
                                                </q-input>
                                            </q-item-section>
                                        </q-item>
                                    </div>
                                </q-card>
                            </div>
                        </div>
                    </q-card>
                    <!-- [-] Subscription -->
                    <!-- [+] Promo code -->
                    <b-list-group-item class="list-group-item-accent-primary p-0 my-4">
                        <q-item class="p-0">
                            <div class="col-9 p-0">
                                <q-input square color="primary" type="text" id="promo_code" v-model="sale.promo_code" :label="translate('add_sale_promo_code')" />
                            </div>
                            <div class="col-4 py-3 pl-1">
                                <b-row class="m-0">
                                <b-col class="pl-0">
                                    <q-btn type="submit" no-caps color="info">
                                        <i class="fas fa-calculator mr-1"></i> {{ translate('apply_code') }}
                                    </q-btn>
                                </b-col>
                            </b-row>
                            </div>
                        </q-item>
                    </b-list-group-item>
                    <!-- [-] Promo code -->
                    <!-- [+] Calculate sale -->
                    <!-- [-] Calculate sale -->
                </b-form>
                <b-list-group-item v-if="showFinish" class="my-4">
                    <q-item class="p-0 full-width">
                        <q-item-section>
                            <strong v-if="response_sales_compute.amount != null">{{ translate('total_amount') }}: {{response_sales_compute.amount}}<template v-if="currency"> {{ currency }}</template></strong>
                        </q-item-section>
                    </q-item>
                    <q-item v-if="$q.screen.lt.sm" class="full-width p-0">
                        <q-item-section>
                            <q-btn class="full-width" @click.prevent="createSale" no-caps color="primary">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('buy_button_text') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section v-if="!$q.screen.lt.sm" side>
                            <q-btn @click.prevent="createSale" no-caps color="primary">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('buy_button_text') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import { eventBus } from '../../main';
export default {
    name: 'BuyItem',
    props: ['item_id', 'item_name', 'entity_type', 'get_route', 'panel_title', 'item'],
    data: function () {
        return {
            currency: currency,
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,
            selected_userType: '',

            max_number: '1',
            toasted_duration: this.$toasted_duration,

            selected: '',
            selected_type: 'customer',
            response_sales_compute: {},
            sale_compute: {},
            sale: {
                group_id: 0,
                customer_id: 0,
                credits: 0,
                product_id: 0,
                quantity: 0,
                subscription_id: 0,
                custom_subscription_id: 0,
                valid_from: this.getCurrentDate(),
                amount: '',
                promo_code: '',
                selected_services: {
                    new_number: 0
                },
            },
            edited_selected_services: false,
         
            showFinish: false,
            showCustomers: false,
            showGroups: false,
            checkedCustomer: true,
            checkedGroup: false,

            selected_customer_name: '',
            selected_group_name: '',
            selected_subscription_name: '',

            selected_custom_subscription_credits: 0,
            selected_remaining_custom_subscription_credits: 0,
            custom_subscription_services: [],
            page_custom_subscription_services: 1,
            state: {},
            services_container_height: false,
            getUser: false
        };
    },
    created: async function () {
        this.getElementsWidthHeight()
        this.getCustomSubscriptionInformation()
        
        this.getUser = await this.getCurrentUser()
        if (this.getUser) {
            this.computeSale()
        }
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    computed: {
        showCalculator: function () {
            // return true only if 1 buyer type was selected (me or my group) AND custom subscription credits have been entered
            if (this.item.type === 'custom') {
                return (this.checkedCustomer || this.checkedGroup) && this.edited_selected_services
            }
            if (this.item.type === 'standard') {
                // standard subscription.
                // return true only if 1 buyer type was selected (me or my group)
                return this.checkedCustomer || this.checkedGroup
            }
            return false
        },
        max_number_error: function () {
            return this.translate('max_amount_allowed')  + ' ' + this.max_number
        },
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        closePanel() {
            this.$emit('closePanel', {
                response: true
            })
        },
        onUpdateDateValidFrom: function (newValue) {
            this.$nextTick(function () {
                this.$refs.qDateProxy.hide()
            })
        },
        subscription_value: function (element_id, subscription_type) {
            if (subscription_type === 'custom') {
                return 'custom_subscription_' + element_id
            } else {
                return 'subscription_' + element_id
            }
        },
        getCustomSubscriptionInformation: function () {
            var id = this.item.id
            var subscriptionUrlCustom = baseUrl +
                'custom-subscriptions/' + id
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            axios.get(subscriptionUrlCustom, {
                    headers: headers
                })
                .then(response => {
                    this.custom_subscription_services = response.data.item.services
                    this.selected_custom_subscription_credits = response.data.item.included_credits
                    this.selected_remaining_custom_subscription_credits = response.data.item.included_credits
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        resetSalesIds: function () {
            this.sale.subscription_id = 0
            this.sale.custom_subscription_id = 0
            this.sale.service_id = 0
            this.sale.product_id = 0
        },
        setRemainingCredits: function () {
            var credits = 0

            if (this.sale.selected_services) {
                for (let [key, value] of Object.entries(this.sale.selected_services)) {
                    credits = credits + this.sale.selected_services[key]
                }
            }

            this.selected_remaining_custom_subscription_credits = parseInt(this.selected_custom_subscription_credits) - parseInt(credits)
        },
        setSelectedSubscriptionServices: function (service) {
            // Set the custom_subscription_id value.
            this.sale.custom_subscription_id = this.item.id

            // Build the object data.
            var service_credits = parseInt(service.new_number) ? parseInt(service.new_number) : 0
            var service_id = service.service_id
            var service_id_string = service_id.toString()
            if (service.new_number) {
                this.edited_selected_services = true
            } else {
                this.edited_selected_services = false
            }

            this.sale.selected_services[service_id_string] = parseInt(service_credits)

            if (this.sale.selected_services[service_id_string] === 0) {
                // remove it from array.
                delete this.sale.selected_services[service_id_string]
            }

            this.setRemainingCredits()
        },
        showCustomersList: function () {
            if (!this.checkedCustomer) {
                this.hideAllLists()
                this.resetBuyerLists()
                this.selected_userType = null
                this.checkedCustomer = true
            }
            this.showCustomers = !this.showCustomers
            this.getCurrentUser()
        },
        showGroupList: function () {
            this.getOwnedGroup()
            if (!this.checkedGroup) {
                this.hideAllLists()
                this.resetBuyerLists()
                this.selected_userType = null
                this.checkedGroup = true
            }
            this.showGroups = !this.showGroups
        },
        resetBuyerLists: function () {
            this.checkedCustomer = false
            this.showCustomers = false
            this.checkedGroup = false
            this.showGroups = false
            this.selected_customer_name = ''
            this.selected_group_name = ''
        },
        hideAllLists: function () {
            this.showCustomers = false
            this.showGroups = false
            this.showSubscriptions = false
        },
        getCurrentUser: function () {
            return new Promise((resolve) => {
                var url = baseUrl +
                    'users/current'
                var headers = {
                    'Authorization': 'Bearer ' + this.accessToken(),
                }
                axios.get(url, {
                        headers: headers
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.selected_customer_name = response.data.item.first_name + ' ' + response.data.item.last_name
                            this.customer_id = response.data.item.customer_id
                            resolve(true)
                        }   
                        resolve(false)
                    })
                    .catch(function (error) {
                        // console.log(error)
                        resolve(false)
                    })
            })
        },
        getOwnedGroup: function () {
            var url = baseUrl +
                'customers/' + this.customer_id + '/groups'
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    if (response.data.status) {
                        if (response.data.items) {
                            let group = response.data.items[0]
                            if (group.customer_is_owner) {
                                this.selected_group_name = group.name
                                this.sale.group_id = group.id
                            }
                        }
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        computeSale: function () {
                if (this.selected_type == 'group') {
                    this.sale.customer_id = 0
                }
                if (this.selected_type == 'customer') {
                    this.sale.customer_id = this.customer_id
                    this.sale.group_id = 0
                }

                if (this.item.type === 'standard') {
                    this.sale.selected_services = []
                }
                this.sale.subscription_id = this.item.type === 'standard' ? this.item.id : 0
                this.sale.custom_subscription_id = this.item.type === 'custom' ? this.item.id : 0

                var data = {
                    'customer_id': this.sale.customer_id,
                    "service_id": 0,
                    "credits": 0,
                    "product_id": 0,
                    "quantity": 0,
                    'group_id': this.sale.group_id,
                    'valid_from': this.sale.valid_from,
                    'custom_subscription_id': this.sale.custom_subscription_id,
                    'subscription_id': this.sale.subscription_id,
                }
                
                if (this.item.type === 'custom') {
                    // remove the temporary value new_number from selected_service.
                    delete this.sale.selected_services['new_number']
                    data['selected_services'] = this.sale.selected_services
                }

                // Send the promo_code only if there is value.
                if (this.sale.promo_code) {
                    data['promo_code'] = this.sale.promo_code
                }

                var headers = {
                    'Authorization': 'Bearer ' + this.accessToken(),
                    'Content-Type': 'application/json'
                }

                this.sale_compute = data
                // http://dynamic:5555/online-payments/euplatesc/params-request

                axios.post(baseUrl + 'sales/compute', data, {
                        headers: headers
                    })
                    .then(response => {
                        var key = response.data.key ? '_' + response.data.key : ''
                        var message = 'add_sale_' + response.data.msg + key
                        var translated_message = this.translate(message)

                        if (response.data.status) {
                            this.response_sales_compute = response.data
                            this.showFinish = true
                            this.message = 'Finish the sale'
                            var compute_message = this.translate('add_sale_options_calculated' + key)
                            if (!this.getUser) {
                                this.$toasted.success(compute_message, {
                                    duration: this.$toasted_duration
                                })
                            }
                            this.getUser = false;
                        } else {
                            this.$toasted.error(translated_message, {
                                duration: this.$toasted_duration
                            })
                        }
                    })
                    .catch(error => {
                        if (error) {
                            this.$toasted.error('An error has occured', {
                                duration: this.$toasted_duration
                            })
                        }
            })
        },
        createSale: function () {
            var data = {
                'customer_id': this.sale_compute.customer_id,
                'group_id': this.sale_compute.group_id,
                'service_id': this.sale_compute.service_id,
                'credits': this.sale_compute.credits,
                'product_id': this.sale_compute.product_id,
                'quantity': this.sale_compute.quantity,
                'valid_from': this.sale_compute.valid_from,
                'subscription_id': this.sale_compute.subscription_id,
                'custom_subscription_id': this.sale_compute.custom_subscription_id,

                'amount': this.response_sales_compute.amount,
                'promo_code_id': this.response_sales_compute.promo_code_id,
                'promo_standard_id': this.response_sales_compute.promo_standard_id,
                'status': this.response_sales_compute.status,
                'valid_until': this.response_sales_compute.valid_until,
            }

            data['selected_services'] = this.sale.selected_services

            // Send the promo_code only if there is value.
            if (this.sale.promo_code) {
                data['promo_code'] = this.sale.promo_code
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            axios.post(baseUrl + 'sales', data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'add_sale_' + response.data.msg + key
                    var translated_message = this.translate(message)

                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        if (response.data.sale_id) {
                            this.emitEventOpenAddSalePaymentCustomer(response)
                        }
                        this.$emit('closePanel', {
                            status: response.data.status
                        })
                        // Redirect to Orders (Sales) page after successful sale creation.
                        // Use undefined for the second argument to fix reduntant navigation to current location error.
                        this.$router.push({
                            name: 'Sales',
                            params: { tab_type: this.selected_type}
                        }, undefined, () => { 
                            location.href = this.$route.hash 
                        })
                        
                    } else {
                        this.$toasted.error('The action couldn\'t be made', {
                            duration: this.$toasted_duration
                        })
                    }

                })
                .catch(error => {
                    var msg = error.response.data.msg
                    if (msg != undefined) {
                        this.msg = msg
                        this.$toasted.error('An error has occured', {
                            duration: this.$toasted_duration
                        })
                    }
                })
        },
        emitEventOpenAddSalePaymentCustomer: _.debounce(function (response) {
            eventBus.$emit('open_add_sale_payment_customer', {sale_id: response.data.sale_id, sale: this.item})
        }, 500),
        getCurrentDate: function () {
            var currentDateWithFormat = new Date().toJSON().slice(0, 10)
            return currentDateWithFormat
        },
    }
}
</script>

<style lang="scss" scoped>
.included-services-block {
    padding: 0.5rem !important;
    background-color: #defffa;
    border-top: 2px solid #59a9c7;
    border-bottom: 2px solid #59a9c7;
}
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    padding-top: 0;
    padding-bottom: 0;
    min-height: 35px;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    &[disabled="disabled"] {
        border-color: rgba(0, 0, 0, .2);
        background: rgba(0, 0, 0, .2);
    }

    .q-radio {
        transform: scale(0.85);
    }
}
</style>