<template>
<offers-details-container :item="service" :item_id="service_id" :baseUrl="baseUrl" :closePanel="closePanel">
    <template v-if="!service.appointment_service" slot="list_subscriptions">
        <q-separator />
        <b-list-group class="list-group-accent">
            <b-list-group-item class="list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase small">
                {{ translate('subscriptions') }}
            </b-list-group-item>
            <q-separator />
            <b-list-group-item class="list-group-item-accent-info list-group-item-divider">
                <p v-if="service.requires_subscription">
                    {{ translate('this_service_is_available_only_with_the_following_subscriptions') }}
                </p>
                <p v-if="!service.requires_subscription">
                    {{ translate('this_service_is_also_available_with_the_following_subscriptions') }}
                </p>
                <offers-list v-if="subscriptions" :list="subscriptions" :typeEnable="true" :baseUrl="baseUrl" :showDetailPanel="showDetailPanel" :showShopPanel="showShopPanel" />
                <infinite-loading slot="append" @infinite="getSubscription" />
            </b-list-group-item>
        </b-list-group>
    </template>
</offers-details-container>
</template>

<script>
import OffersDetailsContainer from '../../../template-parts/OffersDetailsContainerCustomer'
import OffersList from '../../../template-parts/OffersListCustomer'
import DetailSubscription from '../../subscriptions/show/SubscriptionDetails'
import ShopSubscription from '../../subscriptions/forms/BuySubscription'

export default {
    name: 'ServiceDetails',
    props: ['service_id', 'baseUrl', 'service_needs_subscription'],
    components: {
        'offers-details-container': OffersDetailsContainer,
        'offers-list': OffersList
    },
    data() {
        return {
            service: {
                name: null
            },
            service_price: {
                base_price: null,
                subscription_price: null,
                valid_from: null
            },
            msg: null,
            subscription_active: 'active',
            subscriptions: [],
            page: 1,
            total_subscription_pages: 1,
        }
    },
    created() {
        this.getIdService()
    },
    methods: {
        getSubscription: function ($state) {
            if (this.subscription_active === 'active') {
                var subscriptionUrl = baseUrl +
                    'subscriptions/service/' + this.service_id + '?page=' + this.page +
                    '&items_per_page=' + this.$items_per_page
                var subscriptionUrlCustom = baseUrl + 
                    'custom-subscriptions/service/' + this.service_id + '?page=' + this.page +
                    '&items_per_page=' + this.$items_per_page
            } else if (this.subscription_active === '') {
                this.page = 1
                this.subscriptions = []
                $state.complete()
            }
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            axios.get(subscriptionUrl, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        this.subscriptions.push(...response.data.items)
                    }
                    this.total_subscription_pages = response.data.total_pages
                    if (this.page < response.data.total_pages) {
                        this.page++
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                }).then(() => {
                    // Get the Custom Subscriptions, if it's on the last page, and push to the existing list.
                    if (this.page >= this.total_subscription_pages) {
                        axios.get(subscriptionUrlCustom, {
                            headers: headers
                        })
                        .then(response => {
                            $state = this.state
                            if (response.data.items && response.data.items.length > 0) {
                                this.subscriptions.push(...response.data.items)
                            }
                            if (this.page >= response.data.total_pages) {   
                                this.page++
                                $state.loaded()
                            } else {
                                $state.complete()
                            }
                        })
                        .catch(function (error) {
                            // console.log(error)
                        })
                    }
                })

        },
        getIdService: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            axios.get(baseUrl + 'services/' + this.service_id, {
                    headers: headers
                })
                .then(response => {
                    this.service = response.data.item
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showDetailPanel: function (id, type) {
            const panelInstance = this.$showPanel({
                component: DetailSubscription,
                props: {
                    baseUrl: this.baseUrl,
                    subscription_id: id,
                    type: type
                }
            })
        },
        showShopPanel: function (id) {
            const panelInstance = this.$showPanel({
                component: ShopSubscription,
                props: {
                    subscription_id: id
                }
            })
        },
        closePanel: function () {
            this.$emit('closePanel')
        }
    }
}
</script>
