<template>
<div class="fit">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless>
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="fas fa-lg fa-swimmer" />
            </template>
            <b-list-group class="list-group-accent">
                <b-list-group-item @click.prevent="isHidden = !isHidden" class="list-group-item-accent-secondary bg-light font-weight-bold text-uppercase cursor-pointer">
                    <q-icon :title="translate('Click to show or hide the included services')" name="list" />&nbsp;
                    <strong class="position-relative pr-4" style="display: inline-block; width: auto;">
                        {{translate('included_services')}}
                        <q-badge color="primary" floating>{{ included_services && included_services.length ? included_services.length : '0' }}</q-badge>
                    </strong>
                </b-list-group-item>
                <b-list-group-item v-if="!isHidden && included_services && included_services.length" class="list-group-item-accent-info">
                    <div class="list-container-scroll vh-31">
                        <q-item class="list-item" clickable ripple v-for="(service,i) in included_services" :key="'included-service-' + i + service.id">
                            <q-item-section side>
                                <div class="image-thumbnail m-0">
                                    <img v-if="service.main_image_link" :src="baseUrl + service.main_image_link" />
                                    <i v-else class="fa fa-photo fa-2x"></i>
                                </div>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>{{ service.service_name }}</q-item-label>
                                <q-item-label v-if="subscription_type !== 'custom' ? service.credits :null" caption>{{ translate('credits') }}: {{ service.credits }}</q-item-label>
                            </q-item-section>
                            <q-item-section side>
                                <q-btn dense icon="delete" @click="showDeletePanel(service.id)" flat color="danger" />
                            </q-item-section>
                        </q-item>
                    </div>
                    <div v-if="!included_services">
                        <q-item class="m-0 pt-3">{{ translate('No included services') }}</q-item>
                    </div>
                </b-list-group-item>
                <q-separator />
                <b-list-group-item class="list-group-item-accent-secondary bg-light font-weight-bold text-uppercase">
                    <q-icon name="add" />&nbsp;
                    {{translate('include_service')}}
                </b-list-group-item>
                <b-list-group-item class="list-group-item-accent-info">
                    <include-item-form :max_number="max_number" :search_placeholder="translate('find_service')" :item_type="subscription_type" @update_list="updateIncludedItems" :baseUrl="baseUrl" :item_id="subscription_id" :post_url="post_url" :get_url="get_url" :entity_type="entity_type" />
                </b-list-group-item>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import {
    eventBus
} from "../../../../main"

import DeleteSubscriptionIncludedItem from './DeleteSubscriptionIncludedItem'
import IncludeItemForm from '../../../template-parts/IncludeItemForm'

export default {
    name: 'AddIncludedServices',
    props: ['subscription_id', 'subscription_type', 'baseUrl'],
    components: {
        'include-item-form': IncludeItemForm
    },
    computed: {
        max_number_error: function () {
            return this.translate('max_amount_allowed')  + ' ' + this.max_number
        },
        entity_type: function () {
            return this.subscription_type + '_subscription_service'
        }
    },
    data: function () {
        return {
            max_number: '1',
            post_url: this.getPostUrl(),
            get_url: this.getGetUrl(),
            isHidden: false,
            included_services: {},
        }
    },
    created() {
        this.getIncludedServices()
    },
    methods: {
        getPostUrl: function() {
            if (this.subscription_type === 'custom') {
                return 'custom-subscriptions-services'
            }
            return 'subscriptions-services-credits'
        },
        getGetUrl: function() {
            if (this.subscription_type === 'custom') {
                return 'custom-subscriptions/' + this.subscription_id + '/non_included_services'
            }
            return 'subscriptions/' + this.subscription_id + '/non_included_services'
        },
        updateIncludedItems: function () {
            this.included_services = []
            this.getIncludedServices()
        },
        getIncludedServices: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            var subscription_route_base = this.getPostUrl()

            axios.get(baseUrl + subscription_route_base + '/' + this.subscription_id, {
                    headers: headers
                })
                .then(response => {
                    this.included_services = response.data.items
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showDeletePanel: function (item_id) {
            const panelInstance = this.$showPanel({
                component: DeleteSubscriptionIncludedItem,
                props: {
                    item_id: item_id,
                    entity_type: this.subscription_type + '_subscription_service',
                    post_url: this.post_url,
                    panel_title: this.translate('delete_subscription_included_service'),
                    panel_message: this.translate('deleting_subscription_included_service')
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.updateIncludedItems()
                        this.$emit('update_list')
                        eventBus.$emit('update_all')
                    }
                })
        },
        closePanel: function () {
            this.$emit('closePanel', {})
        }
    }
}
</script>

<style lang="scss" scoped>
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }
}
</style>
