<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="fa fa-clone" class="mr-1" /><strong>{{ translate('clone_' + subscription_type + '_subscription') }}</strong>
            </template>
            <p class="p-3 mb-0">{{translate('cloning_subscription')}}</p>
            <q-input dense square color="primary" type="text" id="subscription_name" v-model="name" :label="translate('enter_subscription_clone_name')" />
            <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                <q-item class="p-3">
                    <q-item-section side>
                        <q-btn type="submit" no-caps color="info" @click.prevent="cloneSubscription">
                            <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('yes') }}
                        </q-btn>
                    </q-item-section>
                    <q-item-section></q-item-section>
                    <q-item-section side>
                        <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                            <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('no') }}
                        </q-btn>
                    </q-item-section>
                </q-item>
            </div>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import {eventBus} from '../../../../main'

export default {
    name: 'CloneSubscription',
    props: ['subscription_id', 'subscription_type'],
    data: function () {
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            msg: null,
            name: null
        }
    },
    created: function () {
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        cloneSubscription: function () {
            var content_url = 'subscriptions/clone/'
            if (this.subscription_type === 'custom') {
                content_url = 'custom-subscriptions/clone/'
            }
            var subscriptionUrl = baseUrl + content_url + this.subscription_id
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            var data = {
                name: this.name
            }

            axios.post(subscriptionUrl, data, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'clone_' + this.subscription_type + '_subscription_' + response.data.msg + key
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: true
                        })
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        closePanel: function () {
            this.$emit('closePanel')
        }
    }
}
</script>
