<template>
<b-list-group v-if="list && list.length > 0" class="row flex-flow-wrap offers-cards">
    <b-list-group-item v-for="(item,i) in list" :key="'itemc-' + i + '-' + item.id" :id="'itemc-' + i + '-' + item.id" class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-5ths p-2 mt-2">
        <b-card class="m-0 full-height">
            <template v-if="item.main_image_link">
                <q-carousel height="200px" class="mb-3 product-main-image" v-model="slide">
                    <q-carousel-slide :name="1" class="p-0">
                        <img :src="baseUrl + item.main_image_link" class="slide-main-image" />
                    </q-carousel-slide>
                </q-carousel>
            </template>
            <template v-else>
                <q-carousel height="200px" class="mb-3 product-main-image no-image" v-model="slide">
                    <q-carousel-slide :name="1">
                        <div class="absolute-center custom-caption text-center">
                            <q-icon name="image" />
                        </div>
                    </q-carousel-slide>
                </q-carousel>
            </template>
            <b-row class="m-0">
                <b-col md="12">
                    <p class="mb-0 text-center">
                        <strong class="align-middle">{{ item.name }}</strong>
                        <template v-if="item.price">
                            <br />
                            <small v-if="!item.current_promotion">
                                {{ item.price }}<template v-if="currency"> {{ currency }}</template>
                            </small>
                            <small v-if="item.current_promotion"><s>{{ item.price }}<template v-if="currency"> {{ currency }}</template></s></small>
                            <br />
                            <small v-if="item.current_promotion">
                                {{ item.current_promotion['promotion_price'] }}
                                <template v-if="currency"> {{ currency }}</template>
                            </small>
                        </template>
                    </p>
                    <p v-if="item.description" class="excerpt mb-3 text-center text-muted">
                        {{ item.description | truncate(80, '...') }}
                    </p>
                </b-col>
            </b-row>
            <b-row class="m-0 align-buttons-bottom">
                <b-col md="12" class="d-flex justify-content-between p-0 align-items-end">
                    <q-btn v-if="showDetailPanel !== false" @click="showDetailPanel(item.id, item.type)" round color="blue-6">
                        <i class="fas fa-lg fa-info-circle"></i>
                        <q-tooltip :offset="[10, 10]">{{ translate('details') }}</q-tooltip>
                    </q-btn>
                    <template v-if="!item.appointment_service">
                        <q-btn v-if="item.requires_subscription === true" :class="item.requires_subscription === true ? 'disabled' : ''" color="primary" round>
                            <i class="fas fa-lg fa-shopping-cart"></i>
                            <q-tooltip :offset="[10, 10]">{{ disabled_buy_button_message }}</q-tooltip>
                        </q-btn>
                        <q-btn @click="showShopPanel(item.id, item.name, item)" v-if="showShopButton !== false && !item.requires_subscription" color="primary" round>
                            <i class="fas fa-lg fa-shopping-cart"></i>
                            <q-tooltip :offset="[10, 10]">{{ translate('buy') }}</q-tooltip>
                        </q-btn>
                    </template>
                    <template v-else>
                        <q-btn class="disabled" color="primary" round>
                            <i class="fas fa-lg fa-shopping-cart"></i>
                            <q-tooltip :offset="[10, 10]">{{ appointment_disabled_buy_button_message }}</q-tooltip>
                        </q-btn>
                    </template>
                </b-col>
            </b-row>
        </b-card>
    </b-list-group-item>
</b-list-group>
</template>

<script>
import BuyItem from './BuyItemCustomer'
import BuySubscription from './BuySubscriptionCustomer'

export default {
    name: 'OffersList',
    props: ['list', 'typeEnable', 'entity_type', 'buy_panel_title', 'get_route', 'baseUrl', 'showDetailPanel', 'disabled_buy_button_message', 'appointment_disabled_buy_button_message', 'showShopButton'],
    data: function () {
        return {
            currency: currency,
            slide: 1
        }
    },
    methods: {
        showShopPanel: function (id, name, item) {
            var url = baseUrl + 'online-payments/online-payment-services'
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken()
            };
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    let Component = response.data.items.length > 0 ? BuySubscription : BuyItem
                    const panelInstance = this.$showPanel({
                        component: Component,
                        props: {
                            item: item,
                            item_id: id,
                            item_name: name,
                            entity_type: this.entity_type,
                            get_route: this.get_route,
                            panel_title: this.buy_panel_title
                        }
                    })
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.product-main-image {
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -20px;
    width: calc(100% + 40px);
}

.no-image {
    background: rgba(0, 0, 0, .1);

    .q-icon {
        color: rgba(0, 0, 0, .15);
        font-size: 2.5rem;
        line-height: 2.5rem;
    }
}

.offers-cards {
    margin-left: 0;
    margin-right: 0;

    &.list-group {
        max-width: calc(100% + 16px)!important;
        width: calc(100% + 16px)!important;
        margin-left: -8px!important;
        margin-right: -8px!important;
    }

    .card {
        overflow: hidden !important;
    }
    .card-body {
        justify-content: stretch;
        display: flex;
        flex-direction: column;

        .align-buttons-bottom {
            flex-grow: 1;
            justify-content: flex-end;
            display: flex;
            flex-direction: column;

            > div {
                height: auto !important;
            }
        }
    }
    .excerpt {
        font-size: .85rem;
    }
}

@media (max-width: 767px) {
    .q-carousel.product-main-image {
        height: 90vw !important;
    }
}
</style>
