<template>
<div>
    <b-card class="m-0 mb-0 p-0" no-body no-header>
        <search-wrapper-card :active="subscription_active" :activeStatus="activeStatus" :inactive="subscription_inactive" :inactiveStatus="inactiveStatus" :form_type="subscription_type">
            <q-input slot="search_input" bottom-slots @input="isTyping = true" v-model="searchQuery" id="filternamepredefined" class="search-input" type="text" :placeholder="translate('find_subscription')" :dense="true">
                <template v-slot:prepend>
                    <q-icon name="search" />
                </template>
            </q-input>
            <template slot="add_button" v-if="subscription_type === 'predefined'">
                <q-btn no-caps v-if="!$q.screen.lt.sm" color="primary" @click.prevent="addSubscriptionPredefinedPanel">
                    <i class="fa fa-plus mr-1"></i> {{ translate('add_subscription') }}
                </q-btn>
                <q-btn dense round v-if="$q.screen.lt.sm" class="bg-white text-primary" icon="add" @click.prevent="addSubscriptionPredefinedPanel" />
            </template>
            <template slot="add_button" v-if="subscription_type === 'custom'">
                <q-btn no-caps v-if="!$q.screen.lt.sm" color="primary" @click.prevent="addSubscriptionCustomPanel">
                    <i class="fa fa-plus mr-1"></i> {{ translate('add_subscription') }}
                </q-btn>
                <q-btn dense round v-if="$q.screen.lt.sm" class="bg-white text-primary" icon="add" @click.prevent="addSubscriptionCustomPanel" />
            </template>
        </search-wrapper-card>
    </b-card>
    <draggable v-model="main_items" handle=".handle" :group="'subscriptions'+subscription_type" class="list-group" v-bind="dragOptions" @start="startDrag" @end="endDrag" @change="setItemPosition" :sort="true">
        <transition-group type="transition" :name="!drag ? 'flip-list' : null" :class="'list_wrapper-subscriptions'+subscription_type">
            <q-item 
                v-model="main_items" ref="main_list" v-for="(subscription,i) in main_items" 
                :key="subscription.type + '-subscription-' + i + '-' + subscription.id" 
                :id="subscription.type + '-subscription-' + i + '-' + subscription.id" 
                :class="subscription.is_active ? 'subscription-active' : 'subscription-inactive'" 
                class="list-item full-width draggable-item">
                <q-item-section v-if="!$q.screen.lt.sm" side class="pl-0 handle">
                    <q-btn size="sm" flat color="dark" icon="fas fa-grip-horizontal" class="bg-blue-2 cursor-move full-width full-height" :label="i+1" stack>
                        <q-tooltip :offset="[10, 10]">{{ translate('drag_and_drop_to_change_order') }}</q-tooltip>
                    </q-btn>
                </q-item-section>
                <q-item-section class="p-0" side>
                    <div class="image-thumbnail cursor-pointer handle" @click.prevent="showEditSubscriptionPanel(subscription.id, subscription.type, subscription.is_active)">
                        <img v-if="subscription.main_image_link" :src="subscription.main_image_link" />
                        <i v-else class="fa fa-photo fa-2x"></i>
                    </div>
                </q-item-section>
                <q-item-section class="full-height align-self-center pt-2 pb-2">
                    <strong class="text-muted">{{ subscription.name }}</strong>
                </q-item-section>
                <!-- [+] Desktop sections. -->
                <template v-if="!$q.screen.lt.sm">
                    <q-item-section side class="full-height align-self-center pt-2 pb-2">
                        <q-btn icon="fa fa-clone" round color="teal-6" @click="showClonePanel(subscription.id, subscription.type)">
                            <q-tooltip :offset="[10, 10]">{{ translate('clone_subscription') }}</q-tooltip>
                        </q-btn>
                    </q-item-section>
                    <q-item-section v-if="subscription.type != 'custom'" side class="full-height align-self-center pt-2 pb-2">
                        <q-btn round color="indigo-6" @click="showProductPanel(subscription.id, subscription.type)">
                            <i class="fas fa-lg fa-box-open"></i>
                            <q-tooltip :offset="[10, 10]">{{ translate('add_products') }}</q-tooltip>
                        </q-btn>
                    </q-item-section>
                    <q-item-section side class="full-height align-self-center pt-2 pb-2">
                        <q-btn round color="blue-6" @click="showServicePanel(subscription.id, subscription.type)">
                            <i class="fas fa-lg fa-swimmer"></i>
                            <q-tooltip :offset="[10, 10]">{{ translate('add_services') }}</q-tooltip>
                        </q-btn>
                    </q-item-section>
                    <q-item-section side class="full-height align-self-center pt-2 pb-2">
                        <q-btn round icon="edit" color="primary" @click="showEditSubscriptionPanel(subscription.id, subscription.type, subscription.is_active)">
                            <q-tooltip :offset="[10, 10]">{{ translate('edit_' + entity_type) }}</q-tooltip>
                        </q-btn>
                    </q-item-section>
                    <q-item-section side class="full-height align-self-center pt-2 pb-2">
                        <q-btn round icon="delete" color="danger" @click="showDeletePanel(subscription.id, subscription.type)">
                            <q-tooltip :offset="[10, 10]">{{ translate('delete_' + entity_type) }}</q-tooltip>
                        </q-btn>
                    </q-item-section>
                    <q-item-section side class="full-height align-self-center pt-2 pb-2">
                        <toggle-active :is_active="subscription.is_active" :no_label="true" :patch_toggle_route="patch_toggle_route" :item_id="subscription.id" :entity_type="entity_type" />
                    </q-item-section>
                </template>
                <!-- [-] Desktop sections. -->
            </q-item>
        </transition-group>
    </draggable>
    <infinite-loading slot="append" :identifier="subscription_type + '-' + infSubscription" @infinite="getSubscriptions" />
</div>
</template>

<script>
import {
    eventBus
} from "../../../../main"

import _ from 'lodash'
import SearchWrapperCard from '../../../template-parts/SearchWrapperCard'
import ToggleActive from '../../../template-parts/ToggleActive'
import addSubscriptionCustom from '../forms/AddCustomSubscription'
import addSubscriptionPredefined from '../forms/AddPredefinedSubscription'
import EditSubscription from '../forms/EditSubscription'
import addService from '../forms/AddIncludedServices'
import addProduct from '../forms/AddIncludedProducts'
import makeClone from '../forms/MakeClone'
import DeleteSubscription from '../forms/DeleteSubscription'
import DetailSubscription from '../show/SubscriptionDetails'
import ShopSubscription from '../forms/BuySubscription'
import draggable from 'vuedraggable'

export default {
    name: 'SubscriptionList',
    props: ['subscription_type'],
    components: {
        'search-wrapper-card': SearchWrapperCard,
        'toggle-active': ToggleActive,
        draggable,
    },
    data: function () {
        return {
            baseUrl: baseUrl,
            infSubscription: +new Date(),
            state: {},
            drag: false,
            subscription_active: 'active',
            subscription_inactive: '',
            main_items: [],
            searchQuery: '',
            subscription_status: '',
            isTyping: false,
            page: 1,
        }
    },
    computed: {
        entity_type: function () {
            return 'subscription'
        },
        patch_toggle_route: function () {
            if (this.subscription_type === 'custom') {
                return baseUrl + 'custom-subscriptions'
            }
            return baseUrl + 'subscriptions'
        },
        dragOptions: function () {
            return {
                animation: 0,
                group: "subscriptions" + this.subscription_type,
                disabled: false,
                ghostClass: "ghost"
            }
        },
    },
    mounted: function() {
        this.$on('transition', () => {
            this.resetMainList()
        })
        eventBus.$on('update_image_subscription', (image) => {
            this.updateMainImageLink(image)
        })
        eventBus.$on('toggle_active_subscription', (item) => {
            this.toggleActiveSubscription(item)
        })
        eventBus.$on('delete_subscription', (id) => {
            this.deleteSubscription(id)
        })
    },
    watch: {
        searchQuery: _.debounce(function () {
            this.isTyping = false
        }, 200),
        isTyping: function (value) {
            if (!value) {
                this.resetMainList()
            }
        }
    },
    methods: {
        updateSubscriptionName: function (item) {
            // Find index of specific object using findIndex method,
            var objIndex = this.main_items.findIndex((obj => obj.id === item.subscription.id))
            // and update the element from the main_items array.
            this.main_items[objIndex]['name'] = item.subscription.name ? item.subscription.name : ''
        },
        toggleActiveSubscription: function (item) {
            if (item.id !== null) {
                // Find index of specific object using findIndex method,
                var objIndex = this.main_items.findIndex((obj => obj.id === item.id))
                if (this.main_items[objIndex]) {
                    // And update is_active for the given item in list.
                    this.main_items[objIndex]['is_active'] = item.is_active ? true : false
                    eventBus.$emit('update_toggle_' + this.entity_type, item)

                    if (item.is_active === 0 && this.subscription_active === 'active' && this.subscription_inactive === '') {
                        this.deleteSubscription(item.id)
                    }
                    if (item.is_active === 1 && this.subscription_active === '' && this.subscription_inactive === 'inactive') {
                        this.deleteSubscription(item.id)
                    }
                }
            }
        },
        deleteSubscription: function (id) {
            // Find index of specific object using findIndex method,
            var objIndex = this.main_items.findIndex((obj => obj.id === id))
            // and remove the element from the main_items array.
            this.main_items.splice(objIndex, 1)
        },
        updateMainImageLink: function (image) {
            if (image.id !== null) {
                // Find index of specific object using findIndex method,
                var objIndex = this.main_items.findIndex((obj => obj.id === image.id))
                if (this.main_items[objIndex]) {
                    // And update main_image_link for the given item in list.
                    this.main_items[objIndex]['main_image_link'] = image.image_link ? image.image_link : null
                }
            }
        },
        resetMainList: function () {
            this.page = 1
            this.main_items = []
            this.infSubscription++
        },
        getSubscriptionsUrl: function () {
            var subscription_route_base = 'subscriptions'
            var subscription_status_base = 'subscription_status'

            if (this.subscription_type === 'custom') {
                subscription_route_base = 'custom-subscriptions'
                subscription_status_base = 'custom_subscription_status'
            }

            var subscriptionUrl = baseUrl + subscription_route_base + '?page=' + this.page + '&items_per_page=' + this.$items_per_page

            if (this.subscription_active === 'active' && this.subscription_inactive === '') {
                subscriptionUrl += '&' + subscription_status_base + '=' + this.subscription_active
            } 
            if (this.subscription_active === '' && this.subscription_inactive === 'inactive') {
                subscriptionUrl += '&' + subscription_status_base + '=' + this.subscription_inactive
            }
            
            subscriptionUrl += (this.searchQuery ? '&search=' + this.searchQuery : '')
            
            return subscriptionUrl
        },
        startDrag: function (element, event) {
            this.isDragging = true
        },
        endDrag: function (element) {
            this.isDragging = false
        },
        getDropIndex: function (event) {
            var drop_index = event.oldIndex
            if (event.newIndex > event.oldIndex + 1) {
                drop_index = event.newIndex - 1
            }
            if (event.newIndex < event.oldIndex - 1) {
                drop_index = event.newIndex + 1
            }
            return drop_index
        },
        setItemPosition: function (event) {
            var subscription_drag_id = event.moved.element.id
            var subscriptions = this.main_items
            var accessToken = this.accessToken()
            var drop_index = this.getDropIndex(event.moved)
            var subscription_drop_id = subscriptions[drop_index].id

            var data = {
                "subscription_drag_id": subscription_drag_id,
                "subscription_drop_id": subscription_drop_id
            }
            var headers = {
                'Authorization': 'Bearer ' + accessToken,
            }

            var url_subscription_base = 'subscriptions'
            if (this.subscription_type === 'custom') {
                url_subscription_base = 'custom-subscriptions'
                data = {
                    "custom_subscription_drag_id": subscription_drag_id,
                    "custom_subscription_drop_id": subscription_drop_id
                }
            }

            axios.post(baseUrl + url_subscription_base + '/position', data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    if (response.data.status) {
                        this.$toasted.success('The positions of your subscriptions have been updated', {
                            duration: this.$toasted_duration
                        })
                    } else {
                        this.$toasted.error('The data was not updated', {
                            duration: this.$toasted_duration
                        })
                    }
                })
        },
        activeStatus: _.debounce(function () {
            if (this.subscription_active === 'active') {
                this.subscription_active = ''
            } else {
                this.subscription_active = 'active'
            }
            this.resetMainList()
        }, 200),
        inactiveStatus: _.debounce(function () {
            if (this.subscription_inactive === 'inactive') {
                this.subscription_inactive = ''
            } else {
                this.subscription_inactive = 'inactive'
            }
            this.resetMainList()
        }, 200),
        getSubscriptions: function ($state) {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }            
            var url = this.getSubscriptionsUrl()

            this.state = $state
            // To solve latency with server, push the items into a temporary scoped array and then into the main array.
            let main_items = this.main_items
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        if (response.data.next_page === true) {
                            this.page++
                            $state.loaded()
                        } else {
                            $state.complete()
                        }
                        main_items.push(...response.data.items.map(item => {
                            var image = item.main_image_link ? baseUrl + item.main_image_link : ''
                            item['main_image_link'] = image
                            return item
                        }))
                    } else {
                        // To solve latency with server, push the items into a temporary scoped array and then into the main array.
                        this.main_items = main_items
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        addSubscriptionPredefinedPanel: function () {
            const panelInstance = this.$showPanel({
                component: addSubscriptionPredefined,
            })
            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.resetMainList()
                    }
                })
        },
        addSubscriptionCustomPanel: function () {
            const panelInstance = this.$showPanel({
                component: addSubscriptionCustom,
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.resetMainList()
                    }
                })
        },
        showDeletePanel: function (id, type) {
            const panelInstance = this.$showPanel({
                component: DeleteSubscription,
                props: {
                    subscription_id: id,
                    subscription_type: type
                }
            })
        },
        showServicePanel: function (id, type) {
            const panelInstance = this.$showPanel({
                component: addService,
                props: {
                    subscription_id: id,
                    subscription_type: type,
                    baseUrl: baseUrl
                }
            })
        },
        showClonePanel: function (id, type) {
            const panelInstance = this.$showPanel({
                component: makeClone,
                props: {
                    subscription_id: id,
                    subscription_type: type,
                }
            })

            panelInstance.promise
                .then(result => {
                    this.resetMainList()
                })
        },
        showProductPanel: function (id, type) {
            const panelInstance = this.$showPanel({
                component: addProduct,
                props: {
                    subscription_id: id,
                    subscription_type: type,
                    baseUrl: baseUrl
                }
            })
        },
        showEditSubscriptionPanel: function (id, type, is_active) {
            const panelInstance = this.$showPanel({
                component: EditSubscription,
                props: {
                    subscription_id: id,
                    subscription_type: type,
                    is_active: is_active
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.updateSubscriptionName(result)
                    }
                })
        },
        showDetailPanel: function (id, type) {
            const panelInstance = this.$showPanel({
                component: DetailSubscription,
                props: {
                    subscription_id: id,
                    subscription_type: type
                }
            })
        },
        showShopPanel: function (id, type) {
            const panelInstance = this.$showPanel({
                component: ShopSubscription,
                props: {
                    subscription_id: id,
                    subscription_type: type
                }
            })

            panelInstance.promise
                .then(result => {
                    this.resetMainList()
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.flip-list-move {
    transition: transform 0.5s;
}

.no-move {
    transition: transform 0s;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
    transform-origin: center;
    transform: scale(.5);
}

.list-group {
    min-height: 20px;
}

.list-group-item {
    cursor: move;
}

.search-input {
    margin-left: -15px !important;
}

.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: 0 0 .5rem;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    transition: border-color .1s ease-in-out;

    &:first-child {
        margin-top: .5rem;
    }

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    &.draggable-item:hover {
        border-style: dotted;
        border-color: rgba(0, 0, 0, .85);
    }
}

.subscription-active {
    border-left: 2px solid var(--q-color-primary)!important;
}
.subscription-inactive {
    border-left: 2px solid var(--q-color-dark)!important;
}
</style>
