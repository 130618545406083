<template>
<div>
    <div v-if="!items || items.length == 0">
        <div class="text-muted">{{ translate('no_results') }}</div>
    </div>
    <div v-else>
        <div class="text-muted">{{ translate('results_found') }}: {{ items.length }}</div>
    </div>
    <q-input class="search-input mb-0" @input="isTypingItem = true" v-model="searchItem" id="filternameitem" type="text" :placeholder="search_placeholder ? search_placeholder : ''" :dense="true">
        <template v-slot:prepend>
            <q-icon name="search" />
        </template>
        <template v-slot:append>
            <slot name="add_button" />
        </template>
    </q-input>
    <div class="items-container list-container-scroll" :class="$q.screen.lt.sm ? 'vh-40 list_wraper-' + entity_type + '-' + item_id : 'vh-31 list_wraper-' + entity_type + '-' + item_id">
        <q-item tag="label" class="list-item full-width avatar-red-hover" clickable ripple v-for="(item,i) in items" :key="'list-item-' + i + '-' + item.id">
            <q-item-section v-if="user_image_link" avatar>
                <q-avatar class="cursor-pointer shadow-2">
                    <img v-if="item && item.image_link" :src="item.image_link">
                    <i v-else class="fas fa-user fa-1x text-muted"></i>
                </q-avatar>
            </q-item-section>
            <q-item-section v-else side>
                <div class="image-thumbnail m-0">
                    <img v-if="item.main_image_link" :src="item.main_image_link" />
                    <i v-else class="fa fa-photo fa-2x"></i>
                </div>
            </q-item-section>
            <q-item-section>
                <q-item-label :class="$q.screen.lt.sm ? 'pt-2 text-center small' : ''">
                    {{ item.name }}
                    <strong v-if="item.type" class="text-muted">({{ translate(item.type.replace("s_", '')) }})</strong>
                </q-item-label>
                <template v-if="$q.screen.lt.sm">
                    <div v-if="item_type && item_type !== 'custom'">
                        <q-input
                            class="custom-number-input"
                            :data-item-index="i" :data-item-id="item.id" emit-value v-model.number="item.new_number" min="1" 
                            :data-type="item.type" no-error-icon :rules="max_number !== null && item.type === 's_streaming' ? 
                            [value => item.type === 's_streaming' && value <= max_number || max_number_error] : 
                            [value => value >= 0 || positive_numeric_value_only_error]" 
                            @wheel="$event.target.blur()" type="number" outlined dense>
                            <template v-slot:append>
                                <q-btn v-if="item.new_number >= 0 && item.type !== 's_streaming' || (item.type === 's_streaming' && item.new_number<=max_number)" icon="add" @click.prevent="new_number=item.new_number;registerItem(item)" dense flat color="primary" />
                            </template>
                        </q-input>
                    </div>
                    <div v-else>
                        <q-btn icon="add" @click.stop.prevent="registerItem(item)" dense flat color="primary" />
                    </div>
                </template>
            </q-item-section>
            <template v-if="!$q.screen.lt.sm">
                <q-item-section v-if="item_type && item_type !== 'custom'" side>
                    <q-input 
                        class="custom-number-input"
                        :data-item-index="i" :data-item-id="item.id" emit-value v-model.number="item.new_number" min="1" 
                        :data-type="item.type" no-error-icon :rules="max_number !== null && item.type === 's_streaming' ? 
                        [value => item.type === 's_streaming' && value <= max_number || max_number_error] : 
                        [value => value >= 0 || positive_numeric_value_only_error]" 
                        @wheel="$event.target.blur()" type="number" outlined dense style="max-width: 150px">
                        <template v-slot:append>
                            <q-btn v-if="item.new_number >= 0 && item.type !== 's_streaming' || (item.type === 's_streaming' && item.new_number<=max_number)" icon="add" @click.prevent="new_number=item.new_number;registerItem(item)" dense flat color="primary" />
                        </template>
                    </q-input>
                </q-item-section>
                <q-item-section v-else side>
                    <q-btn icon="add" @click.prevent="registerItem(item)" dense flat color="primary" />
                </q-item-section>
            </template>
        </q-item>
    </div>
    <infinite-loading @infinite="getItems" ref="infiniteLoading" :identifier="'included-items-' + entity_type + '-' + item_id + '-' +infItems" slot="append" />
</div>
</template>

<script>
import {
    eventBus
} from "../../main"

import InfiniteLoading from 'vue-infinite-loading'

export default {
    name: 'IncludeItemForm',
    props: ['post_url', 'get_url', 'item_id', 'baseUrl', 'item_type', 'search_placeholder', 'entity_type', 'user_image_link', 'max_number'],
    watch: {
        searchItem: _.debounce(function () {
            this.isTypingItem = false
        }, 200),
        isTypingItem: function (value) {
            if (!value) {
                this.resetMainList()
            }
        }
    },
    components: {
        'infinite-loading': InfiniteLoading,
    },
    computed: {
        max_number_error: function () {
            return this.translate('max_amount_allowed')  + ' ' + this.max_number
        },
        positive_numeric_value_only_error: function () {
            return this.translate('positive_numeric_value_only_error')
        },
    },
    data: function () {
        return {
            toasted_duration: this.$toasted_duration,
            items: [],
            page: 1,
            state: {},
            infItems: +new Date(),
            isTypingItem: false,
            searchItem: '',
            msg: '',
            new_number: null,
        }
    },
    created() {
        eventBus.$on('update_all', () => {
            this.resetMainList()
        })
    },
    methods: {
        resetMainList: function () {
            this.page = 1
            this.items = []
            this.infItems++
        },
        register_data: function (item) {
            if (this.entity_type === 'service_provider') {
                var data = {
                    'service_id': parseInt(this.item_id),
                    'worker_id': parseInt(item.id)
                }
            }
            if (this.entity_type === 'planning_service_provider') {
                var data = {
                    'planning_id': this.item_id,
                    'worker_id': item.worker_id
                }
            }
            if (this.entity_type === 'standard_subscription_service') {
                var data = {
                    'credits': this.new_number,
                    'service_id': item.id,
                    'subscription_id': this.item_id
                }
            }
            if (this.entity_type === 'custom_subscription_service') {
                var data = {
                    'service_id': item.id,
                    'custom_subscription_id': this.item_id
                }
            }
            if (this.entity_type === 'standard_subscription_product') {
                var data = {
                    'quantity': this.new_number,
                    'product_id': item.id,
                    'subscription_id': this.item_id
                }
            }
            return data
        },
        getItems: function ($state) {
            var url = baseUrl + this.get_url + '?page=' + this.page + '&items_per_page=' + this.$items_per_page
            if (this.entity_type === 'service_provider') {
                url += '&service_id=' + this.item_id
            }
            if (this.entity_type === 'planning_service_provider') {
                url += '&planning_id=' + this.item_id
            }
            if (this.searchItem) {
                url += '&search=' + this.searchItem
            }
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        if (response.data.next_page === true) {
                            this.page++
                            $state.loaded()
                        } else {
                            $state.complete()
                        }
                        this.items.push(...response.data.items.map(entity => {
                            let array = entity
                            array['image_link'] = entity.image_link ? baseUrl + entity.image_link : null
                            array['main_image_link'] = entity.main_image_link ? baseUrl + entity.main_image_link : null
                            return array
                        }))
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        registerItem: function (item) {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            
            var data = this.register_data(item)

            axios.post(baseUrl + this.post_url, data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    var entity_type = this.entity_type ? this.entity_type + '_' : ''
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = entity_type + this.msg + key
                    var translated_message = this.translate(message)

                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        
                        // Visually remove the added item from the list of available items.
                        this.items.splice(this.items.indexOf(item), 1)
                        this.$emit('update_list')
                    } 
                    else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    if (error != undefined) {
                        var msg = error.response.data.msg
                        this.msg = msg
                        this.$toasted.error(msg, {
                            duration: 3000
                        })
                    }

                })
        },
    },
}
</script>

<style lang="scss" scoped>
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    align-items: center;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }
}
@media screen and (max-width: 767px) {
    .q-item.list-item {
        padding: 0 0.5rem;
    }
}
body .search-input {
    width: calc(100% + 30px);
}
@media screen and (max-width: 767px) {
    body .search-input {
        width: 100%!important;
    }
}
</style>
