<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="fas fa-shopping-cart" class="mr-1" /><strong>{{ translate('buy') }}</strong>
            </template>
            <b-list-group class="list-group-accent">
                <div class="p-3 text-center text-uppercase">
                  <h3 class="mb-4 mt-4 text-h6">{{ translate('coming_soon') }}</h3>
                  <h3 class="m-0 text-h6"><i class="fas fa-tools fa-lg text-blue-6"></i></h3>
                </div>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
export default {
    name: 'BuyService',
    data: function () {
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,
        };
    },
    created: function () {
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        closePanel() {
            this.$emit('closePanel', {
                response: true
            })
        }
    }
}
</script>
