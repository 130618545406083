<template>
<div class="animated fadeIn">
    <b-card class="m-0" no-body>
        <div slot="header">
            <h3>{{translate('subscriptions')}}</h3>
        </div>
    </b-card>
    <offers-list :entity_type="entity_type" :get_route="get_route" :buy_panel_title="buy_panel_title" v-if="subscriptions" :list="subscriptions" :baseUrl="baseUrl" :showDetailPanel="showDetailPanel" />
    <infinite-loading slot="append" @infinite="getSubscriptions" />
</div>
</template>

<script>
import OffersList from '../../template-parts/OffersListCustomer'
import _ from 'lodash'
import DetailSubscription from './show/SubscriptionDetails'

export default {
    name: 'SubscriptionsCustomer',
    props: ['baseUrl'],
    components: {
        'offers-list': OffersList
    },
    computed: {
        entity_type: function () {
            return 'subscription'
        },
        get_route: function () {
            return baseUrl + 'subscriptions'
        },
        buy_panel_title: function () {
            return this.translate('buy_subscription')
        },
    },
    data: function () {
        return {
            subscription_active: 'active',
            subscriptions: [],
            subscriptions_custom: [],
            showModal: false,
            subscriptionFinish: false,
            page: 1,
            page_custom: 1,
            total_subscription_pages: 1,
            state: {},
        }
    },
    methods: {
        getSubscriptions: function ($state) {
            var subscriptionUrl = baseUrl +
                'subscriptions?page=' + this.page +
                '&items_per_page=' + this.$items_per_page +
                '&subscription_status=' + this.subscription_active
            var subscriptionUrlCustom = baseUrl + 
                'custom-subscriptions?page=' + this.page +
                '&items_per_page=' + this.$items_per_page +
                '&custom_subscription_status=' + this.subscription_active
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }

            this.state = $state
            axios.get(subscriptionUrl, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    this.total_subscription_pages = response.data.total_pages
                    if (response.data.items && response.data.items.length > 0) {
                        this.subscriptions.push(...response.data.items)
                    } 
                    if (response.data.next_page === true) {
                        this.page++
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                }).then(() => {
                    // Get the Custom Subscriptions, if it's on the last page, and push to the existing list.
                    if (this.page >= this.total_subscription_pages) {
                        axios.get(subscriptionUrlCustom, {
                            headers: headers
                        })
                        .then(response => {
                            if (response.data.items && response.data.items.length > 0) {
                                this.subscriptions_custom.push(...response.data.items)
                            } 
                            if (response.data.next_page === true) {
                                this.page_custom++
                                $state.loaded()
                            } else {
                                $state.complete()
                            }
                            if (this.page_custom >= response.data.total_pages) {
                                this.subscriptions.push(...this.subscriptions_custom)
                            }

                        })
                        .catch(function (error) {
                            // console.log(error)
                        })
                    }
                })

        },
        showDetailPanel: function (id, type) {
            const panelInstance = this.$showPanel({
                component: DetailSubscription,
                props: {
                    baseUrl: this.baseUrl,
                    subscription_id: id,
                    type: type
                }
            })
        },
    }
}
</script>
