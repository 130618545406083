<template>
<div>
    <b-card class="m-0 mb-2" no-body>
        <div slot="header">
            <h3>{{translate('subscriptions')}}</h3>
        </div>
    </b-card>
    <subscription-items />
</div>
</template>

<script>
import SubscriptionItems from './SubscriptionItems'

export default {
    name: 'SubscriptionsWorker',
    components: {
        'subscription-items': SubscriptionItems
    },
}
</script>
