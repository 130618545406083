<template>
<div class="animated fadeIn">
    <subscriptions-worker v-if="showSubscriptionsWorker" :baseUrl="baseUrl"></subscriptions-worker>
    <subscriptions-customer v-if="showSubscriptionsCustomer" :baseUrl="baseUrl"></subscriptions-customer>
</div>
</template>

<script>
import _ from 'lodash'
import SubscriptionsWorker from './SubscriptionsWorker'
import SubscriptionsCustomer from './SubscriptionsCustomer';
export default {
    name: 'Subscriptions',
    components: {
        'subscriptions-worker': SubscriptionsWorker,
        'subscriptions-customer': SubscriptionsCustomer
    },
    data: function () {
        return {
            baseUrl: baseUrl,
            enableCard: false,
        }
    },
    computed: {
        showSubscriptionsWorker: function () {
            if (this.$appSettings.currentUserType() == 'admin') {
                return !this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'worker') {
                return !this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'customer') {
                return this.enableCard;
            }
        },
        showSubscriptionsCustomer: function () {
            if (this.$appSettings.currentUserType() == 'admin') {
                return this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'worker') {
                return this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'customer') {
                return !this.enableCard;
            }
        }
    },
}
</script>
