<template>
<div class="fit sticky-form-actions edit-panel">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <div :class="'pull-right'">
                    <template v-if="!$q.screen.lt.sm">
                        <q-btn no-caps dense color="danger" @click.prevent="showDeletePanel()" icon="delete" :label="translate('delete')" flat />
                        <toggle-active @closePanel="closePanel()" :is_active="is_active" :patch_toggle_route="patch_toggle_route" :item_id="subscription_id" :entity_type="entity_type" />
                        <q-btn no-caps dense color="teal-6" class="ml-2" @click="showClonePanel(subscription_id, subscription_type)" icon="fa fa-clone" :label="translate('clone')" flat />
                    </template>
                    <template v-if="$q.screen.lt.sm">
                        <q-btn no-caps dense color="danger" @click.prevent="showDeletePanel()" icon="delete" flat />
                        <toggle-active :no_label="true" @closePanel="closePanel()" :is_active="is_active" :patch_toggle_route="patch_toggle_route" :item_id="subscription_id" :entity_type="entity_type" />
                        <q-btn no-caps dense color="teal-6" @click="showClonePanel(subscription_id, subscription_type)" icon="fa fa-clone" flat />
                    </template>
                </div>
                <div class="m-1"><q-icon name="edit" class="mr-1" />{{ translate('edit_subscription') }} 
                    <template v-if="subscription_type"> ({{ translate(subscription_type + '_subscription') }})</template>
                </div>
            </template>
            <b-list-group class="list-group-accent overflow-hidden">
                <b-form @submit.prevent>
                    <b-list-group-item class="p-0 pt-2">
                        <div class="row m-0 align-items-center">
                            <div class="col-md p-0">
                                <q-input :label="translate('name')" dense square color="primary" type="text" id="name" v-model="subscription.name" />
                            </div>
                            <div :class="subscription.included_credits != null ? 'col-4' : 'col-6'" class="col-md-2">
                                <q-item v-if="subscription.duration != null" class="p-0 pb-0">
                                    <q-item-section class="pl-2 pr-1 border full-height text-dark font-weight-bold">
                                        <small class="text-muted line-height-1 mt-1">{{ translate('duration') }}</small>
                                        {{ subscription.duration }}
                                    </q-item-section>
                                </q-item>
                            </div>
                            <div v-if="subscription.price != null" :class="subscription.included_credits != null ? 'col-4' : 'col-6'" class="col-md-2 p-0">
                                <q-item class="pt-0 pb-0">
                                    <q-item-section class="pl-2 pr-1 border full-height text-dark font-weight-bold">
                                        <small class="text-muted line-height-1 mt-1">{{ translate('price') }}</small>
                                        {{ subscription.price }}<template v-if="currency"> {{ currency }}</template>
                                    </q-item-section>
                                </q-item>
                            </div>
                            <div v-if="subscription.default_price != null" :class="subscription.included_credits != null ? 'col-4' : 'col-6'" class="col-md-2 p-0">
                                <q-item class="pt-0 pb-0">
                                    <q-item-section class="pl-2 pr-1 border full-height text-dark font-weight-bold">
                                        <small class="text-muted line-height-1 mt-1">{{ translate('default_price') }}</small>
                                        {{ subscription.default_price }}<template v-if="currency"> {{ currency }}</template>
                                    </q-item-section>
                                </q-item>
                            </div>
                            <div v-if="subscription.access_price != null" :class="subscription.included_credits != null ? 'col-4' : 'col-6'" class="col-md-2 p-0">
                                <q-item class="pt-0 pb-0">
                                    <q-item-section class="pl-2 pr-1 border full-height text-dark font-weight-bold">
                                        <small class="text-muted line-height-1 mt-1">{{ translate('access_price') }}</small>
                                        {{ subscription.access_price }}<template v-if="currency"> {{ currency }}</template>
                                    </q-item-section>
                                </q-item>
                            </div>
                            <div v-if="subscription.included_credits != null" :class="subscription.included_credits != null ? 'col-4' : 'col-6'" class="col-md-2 p-0">
                                <q-item class="pt-0 pb-0">
                                    <q-item-section class="pl-2 pr-1 border full-height text-dark font-weight-bold">
                                        <small class="text-muted line-height-1 mt-1">{{ translate('credits') }}</small>
                                        {{ subscription.included_credits }}
                                    </q-item-section>
                                </q-item>
                            </div>
                        </div>
                        <div v-if="subscription.discount_type" class="row m-0 pl-3">
                            <div class="col p-0">
                                <small class="text-uppercase">{{ translate('discount_type') }}</small>
                                <q-radio class="no-pointer-events" disabled color="primary" v-model="subscription.discount_type" :label="subscription.discount_type" :val="subscription.discount_type" />
                            </div>
                            <div class="col p-0">
                                <small class="text-uppercase">{{ translate('discount_value') }}</small>
                                <!-- Fix console label error for 0 value with <subscription.discount_value + ''> -->
                                <q-radio class="no-pointer-events" disabled color="primary" :label="subscription.discount_value !== 0 ? translate(subscription.discount_value) : subscription.discount_value + ''" v-model="subscription.discount_value" :val="subscription.discount_value" />
                            </div>
                        </div>
                        <div>
                            <q-input class="m-0" :label="translate('description')" v-model="subscription.description" square dense color="primary" type="textarea" rows="2" />
                        </div>
                    </b-list-group-item>
                    <template>
                        <b-list-group-item class="list-group-item-accent-info p-0">
                            <q-item @click="showAddServicesPanel()" v-ripple clickable class="align-items-center full-width justify-items-spacebetween">
                                <q-item-label>
                                    <strong>{{ translate('services') }}</strong>
                                </q-item-label>
                                <q-space class="pr-2" />
                                <q-item-section side class="pr-0">
                                    <q-btn flat round class="shadow-2 m-0 p-1 bg-primary" dark>
                                        <i class="fas fa-2x fas fa-swimmer"></i>
                                    </q-btn>
                                </q-item-section>
                            </q-item>
                        </b-list-group-item>
                    </template>
                    <template v-if="subscription_type !== 'custom'">
                        <b-list-group-item class="list-group-item-accent-info p-0">
                            <q-item @click="showAddProductsPanel()" v-ripple clickable class="align-items-center full-width justify-items-spacebetween">
                                <q-item-label>
                                    <strong>{{ translate('products') }}</strong>
                                </q-item-label>
                                <q-space class="pr-2" />
                                <q-item-section side class="pr-0">
                                    <q-btn flat round class="shadow-2 m-0 p-1 bg-primary" dark>
                                        <i class="fas fa-2x fas fa-box-open"></i>
                                    </q-btn>
                                </q-item-section>
                            </q-item>
                        </b-list-group-item>
                    </template>
                    <b-list-group-item class="list-group-item-accent-info">
                        <custom-images :delete_route="delete_route" :entity_type="entity_type" :get_route="get_route" :post_route="post_route" :item_id="subscription_id" :item_images="subscription.images" />
                    </b-list-group-item>
                </b-form>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                            <q-btn type="submit" no-caps color="info" @click.prevent="editSubscription">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import CustomImages from '../../../images/CustomImages'
import ToggleActive from '../../../template-parts/ToggleActive'
import DeleteSubscription from './DeleteSubscription'
import makeClone from './MakeClone'
import AddServices from './AddIncludedServices'
import AddProducts from './AddIncludedProducts'

export default {
    name: 'EditSubscription',
    props: ['subscription_id', 'subscription_type', 'baseUrl', 'is_active'],
    components: {
        'custom-images': CustomImages,
        'toggle-active': ToggleActive
    },
    computed: {
        entity_type: function () {
            return 'subscription'
        },
        post_route: function () {
            if (this.subscription_type === 'custom') {
                return baseUrl + 'custom-subscriptions/images'
            }
            return baseUrl + 'subscriptions/images'
        },
        get_route: function () {
            if (this.subscription_type === 'custom') {
                return baseUrl + 'custom-subscriptions'
            }
            return baseUrl + 'subscriptions'
        },
        patch_toggle_route: function () {
            if (this.subscription_type === 'custom') {
                return baseUrl + 'custom-subscriptions'
            }
            return baseUrl + 'subscriptions'
        },
        delete_route: function () {
            if (this.subscription_type === 'custom') {
                return baseUrl + 'custom-subscriptions/images'
            }
            return baseUrl + 'subscriptions/images'
        },
    },
    data: function() {
        return {
            currency: currency,
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            subscription: {
                description: '',
                included_credits: null,
            },
            msg: null,
        }
    },
    created: function() {
        this.getSubscription()
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    watch: {
        subscription: function () {
            this.$emit('updateitem', this.subscription)
        },
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        getSubscription: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            var subscription_route_base = 'subscriptions/'
            if (this.subscription_type === 'custom') {
                subscription_route_base = 'custom-subscriptions/'
            }
            axios.get(baseUrl + subscription_route_base + this.subscription_id, {
                    headers: headers
                })
                .then(response => {
                    this.subscription = response.data.item
                    this.subscription.images = this.subscription.images.map(image => {
                        image['image_name'] = baseUrl + image.image_name
                        return image
                    })
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        editSubscription: function () {
            var subscription_id = this.subscription_id
            var subscription_name = this.subscription.name
            var subscription_description = this.subscription.description
            var data = {
                'name': subscription_name,
                'description': subscription_description,
            }
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            var subscription_route_base = 'subscriptions/'
            if (this.subscription_type === 'custom') {
                subscription_route_base = 'custom-subscriptions/'
            }
            axios.put(baseUrl + subscription_route_base + subscription_id, data, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'edit_' + this.subscription_type + '_subscription_' + response.data.msg + key
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: true,
                            subscription: { id: subscription_id, name: subscription_name }
                        })                        
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    var msg = error.response.data.msg
                    if (msg != undefined) {
                        this.msg = msg
                        this.$toasted.error('An error has occured', {
                            duration: this.$toasted_duration
                        })
                    }
                })
        },
        showDeletePanel: function () {
            const panelInstance = this.$showPanel({
                component: DeleteSubscription,
                props: {
                    subscription_id: this.subscription_id,
                    subscription_type: this.subscription_type
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.$emit('closePanel', {
                            status: true
                        }) 
                        // Fix issue from slideout closing panel.
                        document.querySelector('body').classList.remove('slideout-panel-open')
                    }
                })
        },
        showClonePanel: function (id, type) {
            const panelInstance = this.$showPanel({
                component: makeClone,
                props: {
                    subscription_id: id,
                    subscription_type: type,
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.$emit('closePanel')
                        // Fix issue from slideout closing panel.
                        document.querySelector('body').classList.remove('slideout-panel-open')
                    }
                })
        },
        showAddServicesPanel: function () {
            const panelInstance = this.$showPanel({
                component: AddServices,
                props: {
                    subscription_id: this.subscription_id,
                    subscription_type: this.subscription_type,
                    baseUrl: baseUrl
                }
            })
        },
        showAddProductsPanel: function () {
            const panelInstance = this.$showPanel({
                component: AddProducts,
                props: {
                    subscription_id: this.subscription_id,
                    subscription_type: this.subscription_type,
                    baseUrl: baseUrl
                }
            })
        },
        closePanel: function () {
            this.$emit('closePanel')
        }
    }
}
</script>
